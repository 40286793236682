/*
 * Copyright 2025 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <valentin@webisoft.com>
 *
 * @neo/connect - queryFunctions.ts
 */

import { Model, Model as ModelRedux }                                              from './Model';
import { QueryFunction, QueryFunctionContext, QueryKey }                           from '@tanstack/react-query';
import API                                                                         from './API';
import Collection                                                                  from '../Collection';
import { ModelAPIAllResponse, ModelAttributes, ModelConstructor, ResponseHeaders } from './types';

export interface GetQueryFnOptions {
  auth?: boolean;
  debug?: boolean,
}

export function getQueryAllModelsFn<Model extends ModelRedux<any>>(
  modelType: new (...args: any) => Model,
  params: Record<string, any>,
  options: GetQueryFnOptions,
): QueryFunction<{ models: Collection<Model> | null, headers: ResponseHeaders }> {
  return ({ signal }: QueryFunctionContext) => {
    return API.all({
      model : modelType,
      params: { ...params, ...(options.debug ? { XDEBUG_TRIGGER: 'start' } : {}) },
      signal: signal,
      auth  : options.auth ?? true,
    });
  };
}

export function getQueryAllModelsPaginatedFn<Attributes extends ModelAttributes, T extends Model<Attributes>>(
  modelType: ModelConstructor<Attributes, T>,
  pageSize: number,
  params: Record<string, any>,
  options: GetQueryFnOptions,
): QueryFunction<
  ModelAPIAllResponse<Attributes>,
  QueryKey,
  { page: number }> {
  return ({ signal, pageParam: { page } }) => {
    return API.all({
      model : modelType,
      params: { ...params, page: page, count: pageSize },
      signal: signal,
      auth  : options.auth ?? true,
    });
  };
}

export function getQueryModelFn<Model extends ModelRedux<any>>(
  modelType: new (...args: any) => Model,
  key: string | number,
  params: Record<string, any>,
  options: GetQueryFnOptions,
): QueryFunction<Model | null> {
  return ({ signal }: QueryFunctionContext) => {
    return API.get(
      modelType,
      key,
      { ...params, ...(options.debug ? { XDEBUG_TRIGGER: 'start' } : {}) },
      signal,
    );
  };
}

export function getQueryModelsFn<Model extends ModelRedux<any>>(
  modelType: new (...args: any) => Model,
  keys: (string | number)[],
  params: Record<string, any>,
  options: GetQueryFnOptions,
): QueryFunction<Collection<Model> | null> {
  return ({ signal }: QueryFunctionContext) => {
    return API.byId(
      modelType,
      keys,
      { ...params, ...(options.debug ? { XDEBUG_TRIGGER: 'start' } : {}) },
      signal,
    );
  };
}
