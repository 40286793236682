/*
 * Copyright 2025 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <valentin@webisoft.com>
 *
 * @neo/connect - index.js
 */

import * as Sentry    from '@sentry/react';
// Log Aggregation
import React          from 'react';
import { createRoot } from 'react-dom/client';
import Setup          from 'Setup';

// Init Sentry monitoring
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn               : 'https://58097e78323272fc6cb7aa1cf9b2df03@o4505716632584192.ingest.sentry.io/4505716684226560',
    integrations      : [
      Sentry.browserTracingIntegration({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [ 'localhost', process.env.CONNECT_API_URL ],
      }),
      Sentry.replayIntegration({
        networkDetailAllowUrls: [
          process.env.CONNECT_API_URL + '*',
        ],
      }),
      Sentry.browserProfilingIntegration(),
      Sentry.browserSessionIntegration(),
    ],
    profilesSampleRate: .25,
    // Performance Monitoring
    tracesSampleRate: .1,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: .25, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const root = createRoot(document.getElementById('connect-wrapper'));
root.render(
  // <React.StrictMode>
  <Setup/>,
  // </React.StrictMode>,
);
