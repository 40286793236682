/*
 * Copyright 2025 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <valentin@webisoft.com>
 *
 * @neo/connect - Contract.ts
 */

import Collection                                                                               from 'library/Collection';
import { HTTPMethod }                                                                           from 'library/Request';
import { DateTime }                                                                             from 'luxon';
import { Actor, Advertiser, Campaign, Client, ContractFlight, ContractReservation, Screenshot } from 'models';
import { Model }                                                                                from 'library/Model';
import { ModelPersistAction }                                                                   from 'library/Model/types';
import { preparePathForSingleModelAction, preparePathParameters }                               from 'library/Model/utils';
import { makeRequest }                                                                          from 'library/Request/Request';
import { makeRoute }                                                                            from 'library/modelsUtils';
import { DateString }                                                                           from 'library/utils/dateTime';
import {
  CompiledPlan,
}                                                                                               from 'scenes/CampaignPlanner/types/common';

function sortFlights(a: ContractFlight, b: ContractFlight) {
  const order = [ 'guaranteed', 'bonus', 'bua' ];
  return order.indexOf(a.type) - order.indexOf(b.type);
}

export const contractStatusColor: { [status in ContractCompletionStatus]: string } = {
  unknown  : 'light-blue',
  ok       : 'light-blue',
  fulfilled: 'success',
  late     : 'warning',
};

export enum ContractDatePeriodStatus {
  NotStarted       = 'not-started',
  StartingSoon     = 'starting-soon',
  RecentlyStarted  = 'recently-started',
  Running          = 'running',
  FinishingSoon    = 'finishing-soon',
  RecentlyFinished = 'recently-finished',
  Finished         = 'finished'
}

export type ContractCompletionStatus = 'unknown' | 'ok' | 'late' | 'fulfilled'

export type FlightPerformanceDatum = {
  flight_id: number,
  network_id: number,
  recorded_at: DateString,
  impressions: number,
  repetitions: number,
}

export interface IContract {
  id: number,
  contract_id: string,
  external_id: string,
  group_id: number | null,
  is_closed: boolean,
  client_id: number,
  client: Client | null,
  advertiser_id: number | null,
  advertiser: Advertiser | null,
  salesperson_id: number,
  salesperson: Actor | null,
  start_date: DateTime | null,
  end_date: DateTime | null,
  additional_flights_imported: boolean,
  expected_impressions: number,
  received_impressions: number,
  created_at: DateTime,
  updated_at: DateTime,
  performances_refreshed_at: DateTime | null,

  flights: Collection<ContractFlight>,
  reservations: Collection<ContractReservation>,
  screenshots: Collection<Screenshot>,
  performances: Collection<FlightPerformanceDatum>,
  campaigns: Collection<Campaign>,

  has_plan: boolean,
  stored_plan: CompiledPlan,
}

class ContractModel extends Model<IContract> {
  _slug = 'contract';

  basePath = '/v1/contracts';

  attributesTypes: { [attr in keyof IContract]?: (sourceAttr: any) => IContract[attr] } = {
    advertiser               : Model.make(Advertiser),
    client                   : Model.make(Client),
    salesperson              : Model.make(Actor),
    reservations             : Collection.ofType(ContractReservation).make,
    screenshots              : Collection.ofType(Screenshot).make,
    flights                  : v => Collection.ofType(ContractFlight).make(v).sort(sortFlights),
    performances             : Collection.ofType(Object).make as (args: any[]) => Collection<FlightPerformanceDatum>,
    campaigns                : Collection.ofType(Campaign).make,
    start_date               : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    end_date                 : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    created_at               : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at               : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    performances_refreshed_at: (d) => DateTime.fromISO(d),
  };

  key: keyof IContract = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof IContract | string)[] } = {
    create: [ 'contract_id' ],
    save  : [ 'salesperson_id', 'is_closed', 'group_id' ],
  };
}

export class Contract extends ContractModel implements IContract {
  client_id!: number;

  client!: Client | null;

  contract_id!: string;

  created_at!: DateTime;

  id!: number;

  reservations!: Collection<ContractReservation>;

  updated_at!: DateTime;

  advertiser_id!: number;

  advertiser!: Advertiser | null;

  external_id!: string;

  group_id!: number | null;

  salesperson_id!: number;

  salesperson!: Actor | null;

  end_date!: DateTime | null;

  start_date!: DateTime | null;

  additional_flights_imported!: boolean;

  expected_impressions!: number;

  received_impressions!: number;

  flights!: Collection<ContractFlight>;

  performances!: Collection<FlightPerformanceDatum>;

  performances_refreshed_at!: DateTime | null;

  screenshots!: Collection<Screenshot>;

  campaigns!: Collection<Campaign>;

  has_plan!: boolean;

  stored_plan!: CompiledPlan;

  completion!: number;

  completionStatus!: ContractCompletionStatus;

  is_closed!: boolean;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

  static async recent() {
    const path           = '/v1/contracts/_recent';
    const pathParameters = preparePathParameters(path, { getKey: () => null });

    return makeRequest<Record<string, any>[]>(makeRoute(path, HTTPMethod.get), pathParameters)
      .then(response => {
        return Collection.ofType(Contract).make(response.data);
      });
  }

  /*
   |--------------------------------------------------------------------------
   | Actions
   |--------------------------------------------------------------------------
   */

  refresh(reimport: boolean = false) {
    const path   = preparePathForSingleModelAction(this.basePath, '/_refresh');
    const params = preparePathParameters(path, this);
    const route  = makeRoute(path, HTTPMethod.post);

    return makeRequest(route, params, {
      reimport: reimport,
    }).then(() => {
      if (!reimport) {
        return this.invalidate();
      }
    });
  }

  /*
   |--------------------------------------------------------------------------
   | Screenshots
   |--------------------------------------------------------------------------
   */

  attachScreenshot(flightId: number, screenshot: Screenshot) {
    const path   = preparePathForSingleModelAction(this.basePath, '/screenshots/{screenshot_id}');
    const params = preparePathParameters(path, this, { screenshot_id: screenshot.getKey() });
    const route  = makeRoute(path, HTTPMethod.post);

    return makeRequest(route, params, {
      flight_id: flightId,
    })
      .then(() => {
        this.invalidate();
      });
  }

  detachScreenshot(flightId: number, screenshot: Screenshot) {
    const path   = preparePathForSingleModelAction(this.basePath, '/screenshots/{screenshot_id}');
    const params = preparePathParameters(path, this, { screenshot_id: screenshot.getKey() });
    const route  = makeRoute(path, HTTPMethod.delete);

    return makeRequest(route, params, {
      flight_id: flightId,
    })
      .then(() => this.invalidate());
  }

  /*
   |--------------------------------------------------------------------------
   | Accessors
   |--------------------------------------------------------------------------
   */

  computeCompletion() {
    this.completion       = this.getCompletion();
    this.completionStatus = this.getCompletionStatus();

    return this;
  }

  getCompletion() {
    return this.expected_impressions > 0 ? this.received_impressions / this.expected_impressions : 0;
  }

  getCompletionStatus(): ContractCompletionStatus {
    const completion = this.getCompletion();

    if (completion === 0 || !this.start_date || !this.end_date) {
      return 'unknown';
    }

    if (completion >= 1) {
      return 'fulfilled';
    }

    const contractSpan   = this.end_date?.diff(this.start_date.startOf('day')!, 'days').days;
    const daysSinceStart = Math.abs(this.start_date.diffNow('days').days);

    const spanCompletion = daysSinceStart / contractSpan;

    if (spanCompletion < 1 && spanCompletion > completion) {
      return 'late';
    }

    if (spanCompletion > 1 && completion < 1) {
      return 'late';
    }

    return 'ok';
  }

  getDatePeriodStatus(): ContractDatePeriodStatus[] {
    const now                                = DateTime.now();
    const status: ContractDatePeriodStatus[] = [];

    if (!this.end_date || !this.start_date) {
      return status;
    }

    if (now > this.end_date) {
      status.push(ContractDatePeriodStatus.Finished);

      if (now < this.end_date.plus({ week: 1 })) {
        status.push(ContractDatePeriodStatus.RecentlyFinished);
      }
    }

    if (now > this.start_date && now < this.end_date) {
      status.push(ContractDatePeriodStatus.Running);

      if (now < this.start_date.plus({ week: 2 })) {
        status.push(ContractDatePeriodStatus.RecentlyStarted);
      }

      if (now > this.end_date.minus({ week: 2 })) {
        status.push(ContractDatePeriodStatus.FinishingSoon);
      }
    }

    if (now < this.start_date) {
      status.push(ContractDatePeriodStatus.NotStarted);

      if (now > this.start_date.minus({ week: 2 })) {
        status.push(ContractDatePeriodStatus.StartingSoon);
      }
    }

    return status;
  }

  getDatesCompletion() {
    if (!this.start_date || !this.end_date) {
      return 0;
    }

    const contractDaysSpan = this.end_date?.diff(this.start_date.startOf('day')!, 'days').days;
    const daysSinceStart   = Math.max(0, -this.start_date.diffNow('days').days);

    return daysSinceStart / contractDaysSpan;
  }
}
