/*
 * Copyright 2025 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <valentin@webisoft.com>
 *
 * @neo/connect - ErrorModal.tsx
 */

import { FlatButton }     from 'components/Button';
import Modal              from 'components/Modal';
import React              from 'react';
import { useTranslation } from 'react-i18next';

interface ErrorModalProps {
  error: Error,
  onClose: () => void,
  onDismiss?: () => void,
}

const ErrorModal = ({ error, onClose, onDismiss }: ErrorModalProps) => {
  const { t } = useTranslation('errors');

  console.error(error);

  return (
    <Modal title={ t('title') } status="danger">
      { process.env.NODE_ENV === 'production' && (
        <>
          <p>
            { t('disclaimer') }
          </p>
          <p>
            { t('help') }
          </p>
        </>
      ) }
      { process.env.NODE_ENV !== 'production' && (
        [
          <pre key="string">
          { error.toString() }
        </pre>,
          <pre key="stack">
        </pre>,
        ]
      ) }
      <div className="row form-actions">
        <FlatButton onClick={ onClose }>
          { t('common:misc.back') }
        </FlatButton>
        { process.env.NODE_ENV !== 'production' && (
          <FlatButton onClick={ onDismiss }>
            { t('common:misc.close') }
          </FlatButton>
        ) }
      </div>
    </Modal>
  );
};

export default ErrorModal;
