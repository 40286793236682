/*
 * Copyright 2025 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <valentin@webisoft.com>
 *
 * @neo/connect - Campaign.ts
 */

import Collection                                                 from 'library/Collection';
import { DateTime }                                               from 'luxon';
import {
  Actor,
  BroadcastTag,
  CampaignLocation,
  CampaignProduct,
  CampaignSchedule,
  Contract,
  ContractFlight,
  ExternalResource,
  Format,
  ResourcePerformance,
  Schedule,
}                                                                 from 'models';
import { Model }                                                  from 'library/Model';
import CampaignStatus                                             from './enums/CampaignStatus';
import { makeRoute }                                              from 'library/modelsUtils';
import { preparePathForSingleModelAction, preparePathParameters } from 'library/Model/utils';
import { HTTPMethod }                                             from 'library/Request';
import { makeRequest }                                            from 'library/Request/Request';
import { formatTimeWithSeconds }                                  from 'library/utils/dateTime';
import { queryClient }                                            from 'library/Model/QueryClient';
import { CampaignGoal }                                           from './enums/CampaignGoal';

export interface CampaignScheduleProduct {
  campaign_id: number,
  schedule_id: number,
  product_id: number,
}

export interface CampaignScheduleLocation {
  campaign_id: number,
  schedule_id: number,
  location_id: number,
}

export interface ICampaign {
  id: number,
  parent_id: number,
  creator_id: number,
  name: string,

  flight_id: number | null,

  static_duration_override: number,
  dynamic_duration_override: number,
  occurrences_in_loop: number,
  priority: number,
  default_schedule_duration_days: number,

  start_date: DateTime,
  start_time: DateTime,
  end_date: DateTime,
  end_time: DateTime,
  broadcast_days: number,

  broadcast_tags: Collection<BroadcastTag>

  created_at: DateTime,
  updated_at: DateTime,
  deleted_at: DateTime | null,
  performances_refreshed_at: DateTime | null,

  status: CampaignStatus,

  parent: Actor,
  creator: Actor,

  schedules: Collection<Schedule>,
  schedules_count: number,
  expired_schedules: Collection<Schedule>,
  locations: Collection<CampaignLocation>

  locations_ids: Collection<{ location_id: number, format_id: number }>
  formats: Collection<Format>,

  schedules_locations_targeting: CampaignScheduleLocation[],
  schedules_products_targeting: CampaignScheduleProduct[],

  flight: ContractFlight | null,
  contract: Contract | null,

  goal_type: CampaignGoal | null,
  goal_value: number,
  goal_reached: boolean,

  external_representations: Collection<ExternalResource>
  performances: Collection<ResourcePerformance>
}

class CampaignModel extends Model<ICampaign> {
  _slug = 'campaign';

  basePath = '/v2/campaigns';

  attributesTypes: { [attr in keyof ICampaign]?: (sourceAttr: any) => ICampaign[attr] } = {
    locations        : Collection.ofType(CampaignLocation).make,
    formats          : Collection.ofType(Format).make,
    schedules        : Collection.ofType(Schedule).make,
    expired_schedules: Collection.ofType(Schedule).make,
    parent           : Model.make(Actor),
    creator          : Model.make(Actor),

    start_date: (d) => DateTime.fromISO(d, { zone: 'utc' }),
    start_time: (d) => DateTime.fromFormat(d, 'HH:mm:ss', { zone: 'utc' }),
    end_date  : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    end_time  : (d) => DateTime.fromFormat(d, 'HH:mm:ss', { zone: 'utc' }),

    broadcast_tags          : Collection.ofType(BroadcastTag).make,
    external_representations: Collection.ofType(ExternalResource).make,
    performances            : Collection.ofType(ResourcePerformance).make,

    flight  : Model.make(ContractFlight),
    contract: Model.make(Contract),

    created_at               : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at               : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    deleted_at               : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    performances_refreshed_at: (d) => DateTime.fromISO(d),
  };

  key: keyof ICampaign = 'id';

  routesAttributes = {
    create: {
      parent_id                     : 'parent_id',
      name                          : 'name',
      flight_id                     : 'flight_id',
      start_date                    : (attr: ICampaign) => attr.start_date.toISODate(),
      start_time                    : (attr: ICampaign) => formatTimeWithSeconds(attr.start_time),
      end_date                      : (attr: ICampaign) => attr.end_date.toISODate(),
      end_time                      : (attr: ICampaign) => formatTimeWithSeconds(attr.end_time),
      broadcast_days                : 'broadcast_days',
      occurrences_in_loop           : 'occurrences_in_loop',
      priority                      : 'priority',
      locations                     : 'locations_ids',
      static_duration_override      : 'static_duration_override',
      dynamic_duration_override     : 'dynamic_duration_override',
      default_schedule_duration_days: 'default_schedule_duration_days',
      goal_type                     : 'goal_type',
      goal_value                    : 'goal_value',
      tags                          : (attr: ICampaign) => attr.broadcast_tags?.pluck('id'),
    },
    save  : {
      parent_id                     : 'parent_id',
      name                          : 'name',
      flight_id                     : 'flight_id',
      start_date                    : (attr: ICampaign) => attr.start_date.toISODate(),
      start_time                    : (attr: ICampaign) => formatTimeWithSeconds(attr.start_time),
      end_date                      : (attr: ICampaign) => attr.end_date.toISODate(),
      end_time                      : (attr: ICampaign) => formatTimeWithSeconds(attr.end_time),
      broadcast_days                : 'broadcast_days',
      occurrences_in_loop           : 'occurrences_in_loop',
      priority                      : 'priority',
      locations                     : 'locations_ids',
      static_duration_override      : 'static_duration_override',
      dynamic_duration_override     : 'dynamic_duration_override',
      default_schedule_duration_days: 'default_schedule_duration_days',
      goal_type                     : 'goal_type',
      goal_value                    : 'goal_value',
      tags                          : (attr: ICampaign) => attr.broadcast_tags?.pluck('id'),
    },
  };
}

export class Campaign extends CampaignModel implements ICampaign {
  id!: number;

  parent_id!: number;

  creator_id!: number;

  name!: string;

  static_duration_override!: number;

  dynamic_duration_override!: number;

  occurrences_in_loop!: number;

  priority!: number;

  default_schedule_duration_days!: number;

  start_date!: DateTime;

  start_time!: DateTime;

  end_date!: DateTime;

  end_time!: DateTime;

  broadcast_days!: number;

  created_at!: DateTime;

  updated_at!: DateTime;

  deleted_at!: DateTime | null;

  status!: CampaignStatus;

  parent!: Actor;

  creator!: Actor;

  schedules!: Collection<Schedule>;

  schedules_count!: number;

  expired_schedules!: Collection<Schedule>;

  locations!: Collection<CampaignLocation>;

  formats!: Collection<Format>;

  locations_ids!: Collection<{ location_id: number; format_id: number; }>;

  broadcast_tags!: Collection<BroadcastTag>;

  external_representations!: Collection<ExternalResource>;

  contract!: Contract | null;

  flight!: ContractFlight | null;

  flight_id!: number | null;

  performances!: Collection<ResourcePerformance>;

  performances_refreshed_at!: DateTime | null;

  schedules_locations_targeting!: CampaignScheduleLocation[];

  schedules_products_targeting!: CampaignScheduleProduct[];

  goal_type!: CampaignGoal | null;

  goal_value!: number;

  goal_reached!: boolean;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

  /*
   |--------------------------------------------------------------------------
   | Actions
   |--------------------------------------------------------------------------
   */

  reorderSchedules(orderedSchedules: number[]) {
    const path   = preparePathForSingleModelAction(this.basePath, '/schedules/_reorder');
    const params = preparePathParameters(path, this);
    const route  = makeRoute(path, HTTPMethod.post);

    return makeRequest(route, params, {
      schedules: orderedSchedules,
    }).then(async () => {
      await queryClient.invalidateQueries({ queryKey: [ (new CampaignSchedule())._slug, 'all' ] });
      return this.invalidate();
    });
  }

  syncLocations(locations: { location_id: number, format_id: number }[]) {
    const path   = preparePathForSingleModelAction(this.basePath, '/locations');
    const params = preparePathParameters(path, this);
    const route  = makeRoute(path, HTTPMethod.put);

    return makeRequest(route, params, {
      locations: locations,
    }).then(async () => {
      await queryClient.invalidateQueries({ queryKey: [ (new CampaignLocation())._slug, 'all' ] });
      return this.invalidate();
    });
  }

  syncProducts(products: number[]) {
    const path   = preparePathForSingleModelAction(this.basePath, '/products');
    const params = preparePathParameters(path, this);
    const route  = makeRoute(path, HTTPMethod.put);

    return makeRequest(route, params, { products })
      .then(async () => {
        await (new CampaignProduct()).invalidateAll();
        return this.invalidate();
      });
  }

}
