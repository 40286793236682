/*
 * Copyright 2025 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <valentin@webisoft.com>
 *
 * @neo/connect - InventoryResourceContractFlight.ts
 */

import { Model }              from 'library/Model';
import { ModelPersistAction } from 'library/Model/types';
import { ContractFlight }     from 'models';

export interface IInventoryResourceContractFlight {
  resource_id: number,

  flight_id: number,
  flight: ContractFlight;

  expected_repetitions: number;
  received_repetitions: number;

  expected_impressions: number;
  received_impressions: number;
}

class InventoryResourceContractFlightModel extends Model<IInventoryResourceContractFlight> {
  _slug = 'inventories-resources-flights';

  basePath = '/v1/inventories-resources/{resource_id}/flights';

  attributesTypes: { [attr in keyof IInventoryResourceContractFlight]?: (sourceAttr: any) => IInventoryResourceContractFlight[attr] } = {
    flight: Model.make(ContractFlight),
  };

  key: keyof IInventoryResourceContractFlight = 'flight_id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof IInventoryResourceContractFlight | string)[] } = {
    create: [],
    save  : [],
  };
}

/**
 * Describes a flight as seen from a specific Inventory Resource (property/product)
 * `expected_*` and `received_*` values are scoped to the specific resource. The underlying Flight is as-is
 */
export class InventoryResourceContractFlight extends InventoryResourceContractFlightModel implements IInventoryResourceContractFlight {
  flight_id!: number;

  resource_id!: number;

  flight!: ContractFlight;

  expected_repetitions!: number;

  received_repetitions!: number;

  expected_impressions!: number;

  received_impressions!: number;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}
