/*
 * Copyright 2025 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <valentin@webisoft.com>
 *
 * @neo/connect - BroadcastResourceType.ts
 */

enum BroadcastResourceType {
  Player     = 'player',
  Location   = 'location',

  Creative   = 'creative',
  Content    = 'content',
  Schedule   = 'schedule',
  Campaign   = 'campaign',

  Advertiser = 'advertiser',
  Tag        = 'tag',
}

export default BroadcastResourceType;
