/*
 * Copyright 2025 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <valentin@webisoft.com>
 *
 * @neo/connect - ContractFlight.ts
 */

import { DateTime } from 'luxon';
import {
  Model,
}                   from 'library/Model';
import {
  ModelPersistAction,
}                   from 'library/Model/types';
import {
  preparePathForSingleModelAction,
  preparePathParameters,
}                   from 'library/Model/utils';
import {
  makeRequest,
}                   from 'library/Request/Request';
import {
  makeRoute,
}                   from 'library/modelsUtils';
import {
  HTTPMethod,
}                   from 'library/Request';
import Collection   from 'library/Collection';
import {
  Campaign,
  Contract,
  ContractLine,
  ContractReservation,
  FlightPerformanceDatum,
  Location,
  Product,
  Screenshot,
}                   from 'models';
import {
  FlightType,
}                   from './enums/FlightType';
import ProductType  from './enums/ProductType';

export type FlightCompletionStatus = 'unknown' | 'ok' | 'late' | 'fulfilled'

export const flightStatusColor: { [status in FlightCompletionStatus]: string } = {
  unknown  : 'light-blue',
  ok       : 'light-blue',
  fulfilled: 'success',
  late     : 'warning',
};

export interface ContractFlightParameters {
  mobile_properties?: { property_id: number, impressions: number }[]

  mobile_product?: number

  mobile_audience_targeting?: string,

  mobile_additional_targeting?: string,

  mobile_website_retargeting?: boolean,

  mobile_online_conversion_monitoring?: boolean,

  mobile_retail_conversion_monitoring?: boolean,
}

export interface IContractFlight {
  id: number,
  contract_id: number,
  name: string,
  start_date: DateTime,
  end_date: DateTime,
  type: FlightType,
  additional_lines_imported: boolean,
  missing_lines_on_import: boolean,
  parameters: ContractFlightParameters,
  created_at: DateTime,
  updated_at: DateTime,

  expected_impressions: number,

  contract: Contract,
  lines: Collection<ContractLine>
  locations: Collection<Location>
  products: Collection<Product>
  campaigns: Collection<Campaign>,
  performances: Collection<FlightPerformanceDatum>,
  screenshots: Collection<Screenshot>,
}

class ContractFlightModel extends Model<IContractFlight> {
  _slug = 'contract-flight';

  basePath = '/v1/flights';

  attributesTypes: { [attr in keyof IContractFlight]?: (sourceAttr: any) => IContractFlight[attr] } = {
    lines       : Collection.ofType(ContractLine).make,
    products    : Collection.ofType(Product).make,
    locations   : Collection.ofType(Location).make,
    contract    : Model.make(Contract),
    performances: Collection.ofType(Object).make as (args: any[]) => Collection<FlightPerformanceDatum>,
    screenshots : Collection.ofType(Screenshot).make,
    campaigns   : Collection.ofType(Campaign).make,
    start_date  : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    end_date    : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    created_at  : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at  : (d) => DateTime.fromISO(d, { zone: 'utc' }),
  };

  key: keyof IContractFlight = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof IContractFlight | string)[] } = {
    create: [],
    save  : [],
  };
}

export class ContractFlight extends ContractFlightModel implements IContractFlight {
  contract_id!: number;

  contract!: Contract;

  created_at!: DateTime;

  end_date!: DateTime;

  id!: number;

  name!: string;

  start_date!: DateTime;

  type!: FlightType;

  updated_at!: DateTime;

  additional_lines_imported!: boolean;

  missing_lines_on_import!: boolean;

  parameters!: ContractFlightParameters;

  lines!: Collection<ContractLine>;

  locations!: Collection<Location>;

  expected_impressions!: number;

  performances!: Collection<FlightPerformanceDatum>;

  products!: Collection<Product>;

  screenshots!: Collection<Screenshot>;

  campaigns!: Collection<Campaign>;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

  syncReservations(reservations: number[]) {
    const path   = preparePathForSingleModelAction(this.basePath) + '/reservations/_sync';
    const params = preparePathParameters(path, this);

    return makeRequest<any[]>(makeRoute(path, HTTPMethod.put), params, {
      reservations: reservations,
    }).then(async (response) => {
      await (new Contract({ id: this.contract_id })).invalidate();
      return Collection.ofType(ContractReservation).make(response.data);
    });
  }


  getCompletion(receivedImpressions: number) {
    return this.expected_impressions > 0 ? receivedImpressions / this.expected_impressions : 0;
  }

  getDatesCompletion() {
    const contractHoursSpan = this.end_date?.diff(this.start_date.startOf('day')!, 'hours').hours;
    const hoursSinceStart   = Math.max(0, -this.start_date.diffNow('hours').hours);

    return hoursSinceStart / contractHoursSpan;
  }

  isStaticOnly() {
    if (this.type === FlightType.Mobile) {
      return false;
    }

    return this.lines.every(line => line.product_type !== ProductType.digital);
  }

  getCompletionStatus(receivedImpressions: number): FlightCompletionStatus {
    return this.computeCompletionStatus(this.expected_impressions > 0 ? receivedImpressions / this.expected_impressions : 0);
  }

  computeCompletionStatus(completion: number): FlightCompletionStatus {
    if (completion === 0 || !this.start_date || !this.end_date) {
      return 'unknown';
    }

    if (completion >= 1) {
      return 'fulfilled';
    }

    const spanCompletion = this.getDatesCompletion();

    if (spanCompletion < 1 && spanCompletion > completion && this.type !== 'bua') {
      return 'late';
    }

    if (spanCompletion > 1 && completion < 1 && this.type !== 'bua') {
      return 'late';
    }

    return 'ok';
  }

  async onSaved(response: Partial<IContractFlight>): Promise<this> {
    await super.onSaved(response);
    await (new Contract({ id: this.contract_id })).invalidate();
    await (new Contract({ id: this.contract_id })).invalidateAll();
    return this;
  }
}
