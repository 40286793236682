/*
 * Copyright 2025 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <valentin@webisoft.com>
 *
 * @neo/connect - ErrorBoundary.tsx
 */

import ErrorModal from './ErrorModal';
import React      from 'react';

class ErrorBoundary extends React.Component<{ children: React.ReactNode }, { error: Error | null }> {
  state = {
    error: null,
  };

  /**
   * @param error
   * @returns {{error: Error}}
   */
  static getDerivedStateFromError(error: Error) {
    console.error(error);
    return { error: error };
  }

  /**
   *
   * @param error
   * @param errorInfo
   */
  componentDidCatch(error: Error, errorInfo: any) {
    console.error(error, errorInfo);
    if (error.name === 'ChunkLoadError') {
      console.warn('Could not load chunk, reloading...');
      window.location.reload();
    }
  }

  handleModalClose = () => {
    this.setState({ error: null });
    console.warn('Error modal closed, reloading');
    window.location.reload();
  };

  handleModalDismiss = () => {
    this.setState({ error: null });
  };

  render() {
    return (
      <React.Fragment>
        { this.state.error && <ErrorModal error={ this.state.error }
                                          onClose={ this.handleModalClose }
                                          onDismiss={ this.handleModalDismiss }/> }
        { !this.state.error && this.props.children }
      </React.Fragment>
    );
  }
}

export default ErrorBoundary;
